import { createPreviewSubscriptionHook, createCurrentUserHook } from "next-sanity";
import sanityImage from "@sanity/image-url";
import { sanityClient } from "./sanity.server";
import { config } from "./config";

/**
 * Set up a helper function for generating Image URLs with only the asset reference data in your documents.
 * Read more: https://www.sanity.io/docs/image-url
 **/
export const imageBuilder = sanityImage(sanityClient);

export const urlFor = (source) => imageBuilder.image(source);

// Set up the live preview subscription hook
export const usePreviewSubscription = createPreviewSubscriptionHook(config);

// Helper function for using the current logged-in user account
export const useCurrentUser = createCurrentUserHook(config);
